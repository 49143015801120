import React from "react";

class Buttons extends React.Component {
	render() {
		return (
			<div className="button-container">
				<button className="exclude" onClick={() => this.props.onExclude()}>Exclude</button>
				<button className="remove" onClick={() => this.props.onRemove()}>Remove</button>
				<button className="re-index" onClick={() => this.props.onReIndex()}>Re-index</button>
				<button className="trim" onClick={() => this.props.onTrim()}>Trim</button>
				<button className="send" onClick={() => this.props.onSend()}>Send</button>
			</div>
		)
	}
}

export default Buttons;