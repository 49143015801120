import React from 'react';

class SeasonSelector extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.timer = null;
	}

	onUserAction(evt) {
		let value = evt.target.value || evt.currentTarget.value;

		if (this.timer) {
			clearTimeout(this.timer);
		}

		this.timer = setTimeout(() => {
			this.processChange(value || null);
		}, 1500);
	}

	processChange(season) {
		if (!season) return;

		this.props.onSeasonSelected(season);
	}

	render() {
		return (
			<div className="season-selector-container">
				<input placeholder="Enter a season (Prophecy, Blight, Delve) ..." type="text" onChange={(evt) => this.onUserAction(evt)}/>
			</div>
		);
	}
}

export default SeasonSelector;