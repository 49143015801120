import React from 'react';
import DownloadIcon from './download.png';
import RemoveIcon from './remove.png';

class Event extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	// API refers to the GGG Ladder API ...
	fetchEventFromAPI() {
		if (!this.props.event.get('name')) return;

		let t = '9cc030e570fec4673fcd1611d737a7feabf3904c';

		let init = {
			headers: {
				'User-Agent': 'OAuth poe-racing.com/1.0.0 (contact: info@poe-racing.com)',
				'Authorization': 'Bearer ' + t
			}
		};

		// Fetch solo ladder
		fetch('https://api.pathofexile.com/league/' + encodeURIComponent(this.props.event.get('name')) + '/ladder?limit=50&offset=0&track=true&realm=pc&sort=depthsolo', init)
			.then(response => response.json())
			.then(json => {
				this.props.event.set('solo', json.ladder.entries);
			})
			.catch(err => {
				this.props.event.set('solo', null);
				console.log(err);
			});

		// Fetch group ladder
		fetch('https://api.pathofexile.com/league/' + encodeURIComponent(this.props.event.get('name')) + '/ladder?limit=50&offset=0&track=true&realm=pc&sort=depth', init)
			.then(response => response.json())
			.then(json => {
				this.props.event.set('group', json.ladder.entries);
			})
			.catch(err => {
				this.props.event.set('group', null);
				console.log(err);
			});
	}

	renderEventContent() {
		if (!this.props.event.get('solo') && !this.props.event.get('group')) {
			return (
				<div className="no-event-content">
					<p>No event content found.</p>
				</div>
			);
		}

		return this.renderDepth(this.props.view);
	}

	renderDepth(key) {
		if (!this.props.event.get(key)) {
			return null;
		}

		const rows = this.props.event.get(key).map((row, idx) => {
			let classes = ['basis-row'];

			if (row.excluded) {
				classes.push('excluded');
			}

			return (
				<div key={idx} className={classes.join(' ')} title={!!row.exclusionReasons ? 'Exclusion reason: ' + row.exclusionReasons.join(', '): null}>
					<span>{row.rank}</span>
					<span>{row.character.name}</span>
					<span>{row.account.name}</span>
					<span>{this.props.view === 'group' ? row.character.depth.default : row.character.depth.solo}</span>
				</div>
			);
		});

		return (
			<div key={key} className="row-container">
				<h3>
					<span className={this.props.view === 'solo' ? 'clickable' : null} onClick={() => this.props.onChangeView('group')}>Group</span>
					<span className={this.props.view === 'group' ? 'clickable' : null} onClick={() => this.props.onChangeView('solo')}>Solo</span>
				</h3>
				{rows}
			</div>
		);
	}

	render() {
		return (
			<div className="event">
				<div className="event-name">
					<input type="text" defaultValue={this.props.event.get('name')} onChange={(evt) => this.props.event.set('name', evt.target.value || evt.currentTarget.value || null)}/>
					<span className="fetcher" onClick={() => this.fetchEventFromAPI()}>
						<img alt="Download" src={DownloadIcon} title={!this.props.event.get('name') ? 'Must specify an event before fetching.' : null} />
					</span>
					<span className="fetcher" onClick={() => this.props.onRemove(this.props.event)}>
						<img alt="Remove" src={RemoveIcon} title={"Remove"} />
					</span>
					<span className="fetcher">
						<span onClick={() => {
							this.props.event.set('ruthless', !this.props.event.get('ruthless'));
							this.forceUpdate();
						}}
							  title={"Ruthless?"}
							  className={["ruthless", this.props.event.get('ruthless') ? 'active' : null].join(' ')}>R</span>
					</span>
				</div>
				<div className="event-content">
					{this.renderEventContent()}
				</div>
			</div>
		)
	}
}

export default Event;
