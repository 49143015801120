import React from 'react';
import './AdminApp.css';
import SeasonSelector from "./SeasonSelector";
import Events from "./Events";
import * as Backbone from "backbone";
import Buttons from "./Buttons";
import moment from "moment";

class AdminApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxEvents: 4,
            season: new Backbone.Collection(),
			skipGroupAwards: true
        };
    }

    componentDidMount() {
        this.state.season.on('reset action-performed change:solo change:group', function() {
            this.setState({
                updated: new Date().getTime()
            });
        }, this);
    }

    componentWillUnmount() {
        this.state.season.off(null, null, this);
    }

    sort(view) {
        let accountsAwardedGroupAward = [],
            SSF_HC = 0,
            SSF_SC = 1,
            HC = 2,
            SC = 3;

        if (this.state.season.length !== 4) {
        	return;
		}

        // Check if the character in HC originates from SSF HC ...
        this.state.season.at(HC).get(view).forEach(row => {
            let hasBeenMigratedWithoutFurtherProgress = this.state.season.at(SSF_HC).get(view).filter(originalRow => {
                return !!originalRow.retired &&
                    originalRow.character.name === row.character.name &&
                    originalRow.account.name === row.account.name &&
                    originalRow.character.level === row.character.level;
            });

            if (hasBeenMigratedWithoutFurtherProgress.length > 0) {
                row.excluded = true;
                
                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('SSF HC');
            }
        });

        // Check if the character in SC originates from HC ...
        this.state.season.at(SC).get(view).forEach(row => {
            let hasDiedWithoutFurtherProgress = this.state.season.at(HC).get(view).filter(originalRow => {
                return !!originalRow.dead &&
                    originalRow.character.name === row.character.name &&
                    originalRow.account.name === row.account.name &&
                    originalRow.character.level === row.character.level;
            });

            if (hasDiedWithoutFurtherProgress.length > 0) {
                row.excluded = true;
                
                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('HC');
            }
        });

        // Check if the character in SC originates from SSF SC ...
        this.state.season.at(SC).get(view).forEach(row => {
            let hasBeenMigratedWithoutFurtherProgress = this.state.season.at(SSF_SC).get(view).filter(originalRow => {
                return !!originalRow.retired &&
                    originalRow.character.name === row.character.name &&
                    originalRow.account.name === row.account.name &&
                    originalRow.character.level === row.character.level;
            });

            if (hasBeenMigratedWithoutFurtherProgress.length > 0) {
                row.excluded = true;
                
                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('SSF SC');
            }
        });
        
        accountsAwardedGroupAward = [];
        
        this.state.season.at(SSF_HC).get(view).forEach(row => {
            if (!!row.excluded) return;

            // If an award is already given to this account, exclude this character ...
            if (accountsAwardedGroupAward.indexOf(row.account.name) >= 0) {
                row.excluded = true;

                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('Award already granted');
            } else {
                accountsAwardedGroupAward.push(row.account.name);
            }
        });

        accountsAwardedGroupAward = [];

        this.state.season.at(SSF_SC).get(view).forEach(row => {
            if (!!row.excluded) return;

            // If an award is already given to this account, exclude this character ...
            if (accountsAwardedGroupAward.indexOf(row.account.name) >= 0) {
                row.excluded = true;

                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('Award already granted');
            } else {
                accountsAwardedGroupAward.push(row.account.name);
            }
        });

        accountsAwardedGroupAward = [];

        this.state.season.at(HC).get(view).forEach(row => {
            if (!!row.excluded) return;

            // If an award is already given to this account, exclude this character ...
            if (accountsAwardedGroupAward.indexOf(row.account.name) >= 0) {
                row.excluded = true;

                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('Award already granted');
            } else {
                accountsAwardedGroupAward.push(row.account.name);
            }
        });

        accountsAwardedGroupAward = [];

        this.state.season.at(SC).get(view).forEach(row => {
            if (!!row.excluded) return;

            // If an award is already given to this account, exclude this character ...
            if (accountsAwardedGroupAward.indexOf(row.account.name) >= 0) {
                row.excluded = true;

                if (!row.exclusionReasons) row.exclusionReasons = [];

                row.exclusionReasons.push('Award already granted');
            } else {
                accountsAwardedGroupAward.push(row.account.name);
            }
        });
    }

    validate() {
        this.state.season.forEach(event => {
            if (!event.get('group') || !event.get('solo')) {
                throw Error("One or more events have not been fully loaded.")
            }
        });
    }

    exclude() {
        this.validate();

        this.sort('solo');
        this.sort('group');

        this.state.season.trigger('action-performed');
    }

    remove() {
        this.validate();

        this.state.season.forEach(event => {
            event.set('group', event.get('group').filter(row => !row.excluded));
            event.set('solo', event.get('solo').filter(row => !row.excluded));
        });

        this.state.season.trigger('action-performed');
    }

    reIndex() {
        this.validate();

        this.state.season.forEach(event => {
            let reIndexedSolo = event.get('solo').map((row, idx) => {
                row.rank = idx + 1;
                return row;
            });
            let reIndexedGroup = event.get('group').map((row, idx) => {
                row.rank = idx + 1;
                return row;
            });

            event.set('group', reIndexedGroup);
            event.set('solo', reIndexedSolo);
        });

        this.state.season.trigger('action-performed');
    }

    trim() {
        this.validate();

        const maxSize = 10;

        this.state.season.forEach(event => {
            event.set('group', this.state.skipGroupAwards ? [] : event.get('group').slice(0, maxSize));
            event.set('solo', event.get('solo').slice(0, maxSize));
        });

        this.state.season.trigger('action-performed');
    }

    send() {
        this.validate();

        let sendModel = new Backbone.Model();

        sendModel.set('awards', this.extract(this.state.season));
        sendModel.set('year', moment().format('YYYY'));
        sendModel.url = 'https://api.poe-racing.com/api/admin/awards';

        sendModel.save()
            .then(() => {
                alert('The awards have been generated.');
            })
            .catch(err => console.log(err));
    }

    extract(collection) {
        return collection.map(event => {
            let evt = event.toJSON();

            // Add Ruthless tag to all events, if the event is marked as Ruthless.
            if (evt.solo) {
                evt.solo = evt.solo.map(award => ({
                    ...award,
                    ruthless:  !!event.get('ruthless')
                }));
            }

            if (evt.group) {
                evt.group = evt.group.map(award => ({
                    ...award,
                    ruthless:  !!event.get('ruthless')
                }));
            }

            return evt;
        });
    }

    render() {
        return (
            <div className="app">
                <SeasonSelector onSeasonSelected={(season) => {
                    let newCollection = [
                        {name: 'SSF ' + season + ' HC'},
                        {name: 'SSF ' + season},
                        {name: 'Hardcore ' + season},
                        {name: season},
                    ];

                    this.state.season.reset(newCollection);
                }} />
                {this.state.season.length > 0 ? <Buttons
                    onExclude={() => this.exclude()}
                    onRemove={() => this.remove()}
                    onReIndex={() => this.reIndex()}
                    onTrim={() => this.trim()}
                    onSend={() => this.send()}
                /> : []}
                <Events season={this.state.season} />
            </div>
        );
    }
}

export default AdminApp;
