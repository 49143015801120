import React from 'react';
import Event from "./Event";

class Events extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			view: 'group'
		}
	}

	componentDidMount() {
		this.props.season.on('remove', function() {
			this.setState({
				updated: new Date().getTime()
			});
		}, this);
	}

	componentWillUnmount() {
		this.props.season.off(null, null, this);
	}

	renderEvents() {
		return this.props.season.map((event, idx) => {
			return <Event onRemove={(event) => this.props.season.remove(event)} onChangeView={(view) => this.setState({ view: view })} view={this.state.view} key={idx} event={event} />;
		});
	}

	render() {
		return (
			<div className="event-container">
				{this.renderEvents()}
			</div>
		);
	}
}

export default Events;